import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddOperation = () => {

    const redirect = useNavigate();  // redirect a page

    // useState for setData
  const [name , setName] = useState("");
  const [email , setEmail] = useState("");
  const [password , setPassword] = useState("");
  const [successMessage , setSuccessMessage] = useState('');
  const [errorMessage , setErrorMessage] = useState('');


//   use function for set Data
  const nameHandler = (event) => {
    setName(event.target.value);
  };
  const emailHandler = (event) => {
    setEmail(event.target.value);
  };
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  // Submit Data
  const SubmitHandler = (e)=>{
    e.preventDefault();
    axios.post("https://6612723295fdb62f24eeb39b.mockapi.io/api/test/crudApp", {
        name : name,
        email : email,
        password: password,
    }, {
      headers: { "Access-Control-Allow-Origin": "*" }
    }).then(() =>{
        setSuccessMessage('Data submitted successfully!');
        setErrorMessage('');
        setName('');
        setEmail('');
        setPassword('');
        redirect('/');  //redirect to read page after successful submission of data
    }).catch(()=>{
        setErrorMessage('Failed to submit data. Please try again.');
        setSuccessMessage('');
    })

  }

  return (
    <div>
      <form onSubmit={SubmitHandler}>
        <div className="container mt-5">
          <h1>Add Operation</h1>
          <div className="row">
            <div className="col-12 mb-3">
              <label className="mb-2">Name</label> <br />
              <input
                type="text" value={name}
                className="form-control"
                onChange={nameHandler}
              />
            </div>
            <div className="col-12 mb-3">
              <label className="mb-2">Email</label> <br />
              <input
                type="email" value={email}
                className="form-control"
                onChange={emailHandler}
              />
            </div>
            <div className="col-12 mb-3">
              <label className="mb-2">Password</label> <br />
              <input
                type="password" value={password}
                className="form-control"
                onChange={passwordHandler}
              />
            </div>
            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </div>
          {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
};

export default AddOperation;
