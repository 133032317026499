import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditOperation = () => {

    const api = 'https://6612723295fdb62f24eeb39b.mockapi.io/api/test/crudApp';
    const { id } = useParams();   // use get id from url
    const redirect = useNavigate(); // redirect a page

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // setState function
    const nameHandler = (e) => {
        setName(e.target.value);
    }
    const emailHandler = (e) => {
        setEmail(e.target.value)
    }
    const passwordHandler = (e) => {
        setPassword(e.target.value)
    }


    useEffect(() => {
        getData();
    }, []);

    // getData according to selected id
    const getData = () => {
        axios.get(`${api}/${id}`) 
            .then((response) => {
                const data = response.data;
                setName(data.name);
                setEmail(data.email);
                setPassword(data.password);
            }).catch((error) => {
                alert('Error fetching data:', error);
            })
    }


    // update Handler
    const UpdateHandler = (e) =>{
        e.preventDefault();
        axios.put(`${ api }/${ id }`,{
            name : name,
            email : email,
            password : password,
        }).then(() =>{
            redirect("/")
        }).catch(() =>{
            alert('Data not Successfully Updated!');
        })
    }
    
    return (
        <div>
            <form onSubmit={UpdateHandler}>
                <div className="container mt-5">
                    <h1>Edit Operation</h1>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="mb-2">Name</label> <br />
                            <input
                                type="text"
                                value={name}
                                className="form-control"
                                onChange={nameHandler}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="mb-2">Email</label> <br />
                            <input
                                type="email"
                                value={email}
                                className="form-control"
                                onChange={emailHandler}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="mb-2">Password</label> <br />
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={passwordHandler}
                            />
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary" type="submit">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditOperation;
