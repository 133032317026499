import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddOperation from './Components/AddOperation';
import EditOperation from './Components/EditOperation';
import ReadOperation from './Components/ReadOperation';



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path='/AddOperation' element={<AddOperation />}></Route>
          <Route exact path='/' element={<ReadOperation />}></Route>
          <Route exact path='/EditOperation/:id' element={<EditOperation />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
