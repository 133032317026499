import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ReadOperation = () => {

// api use https://mockapi.io/
  const api = "https://6612723295fdb62f24eeb39b.mockapi.io/api/test/crudApp";
  const [data, setData] = useState([]);


  useEffect(() => {
    getData();
  }, []);

//   function for getData in API
  function getData() {
    axios.get(api)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

//   function for deleteData in API
  const deleteItem = (id) =>{
    axios.delete(`${api}/${id}`)
    .then(() =>{
        alert("Are you sure You want to Delete item!");
        getData();
    }).catch(() =>{
        alert('Data Not Deleted!')
    })
  }

  return (

    <div className="container mt-5">
      <h1 className="text-center mb-5">Crud Operation</h1>
      <Link to='/AddOperation'><button className='btn btn-primary position-absolute'style={{ right: '100px',top:'60px' }}>Add Data</button></Link>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Password</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <th scope="row">{item.id}</th>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.password}</td>
              <td>
                <Link to={`/EditOperation/${item.id}`}>
                <button type="button" className="btn btn-success">
                  Edit
                </button>
                </Link>
              </td>
              <td>
                <button type="button" className="btn btn-danger" onClick={()=>{deleteItem(item.id)}}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReadOperation;
